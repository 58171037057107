@font-face {
  font-family: 'HelveticaNeueLTPro-Th';
  src: url('HelveticaNeueLTPro-Th.otf');
}
@font-face {
  font-family: 'HelveticaNeueLTPro-Lt';
  src: url('HelveticaNeueLTPro-Lt.otf');
}
@font-face {
  font-family: 'HelveticaNeueLTPro-LtIt';
  src: url('HelveticaNeueLTPro-LtIt.otf');
}
@font-face {
  font-family: 'HelveticaNeueLTPro-UltLt';
  src: url('HelveticaNeueLTPro-UltLt.otf');
}
@font-face {
  font-family: 'HelveticaNeueLTPro-Bd';
  src: url('HelveticaNeueLTPro-Bd.otf');
}
@font-face {
  font-family: 'HelveticaNeueLTPro-BdIt';
  src: url('HelveticaNeueLTPro-BdIt.otf');
}
@font-face {
  font-family: 'HelveticaNeueLTPro-Roman';
  src: url('HelveticaNeueLTPro-Roman.otf');
}
